import { format, parseISO } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { CaseNote } from '../../../shared/types/Session';
import { Flex, Typography } from '../../styledComponents';
import Divider from '../../styledComponents/Divider';

interface NotesCardProps {
  caseNote: CaseNote;
}

const NotesCard = ({ caseNote }: NotesCardProps) => {
  const [showReadMore, setShowReadMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const notesContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (notesContentRef?.current) {
      if (
        notesContentRef.current.offsetHeight <
          notesContentRef.current.scrollHeight ||
        notesContentRef.current.offsetWidth <
          notesContentRef.current.scrollWidth
      ) {
        setShowReadMore(true);
      } else {
        setShowReadMore(false);
      }
    }
  }, [caseNote?.note]);

  const lastUpdatedAt = caseNote?.updatedAt
    ? parseISO(caseNote?.updatedAt)
    : '';

  return (
    <Flex direction="column" gap="1">
      <Typography
        ref={notesContentRef}
        css={{
          lineHeight: '$1',
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
          ...(!isExpanded && {
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }),
          overflow: 'hidden',
        }}
      >
        {caseNote?.note ?? 'N/A'}
      </Typography>
      {showReadMore && !isExpanded ? (
        <Typography
          size="xs"
          role="button"
          tabIndex={0}
          color="gray"
          onClick={() => {
            setIsExpanded(true);
          }}
          css={{
            margin: 0,
            padding: 0,
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          Read more
        </Typography>
      ) : null}
      <Flex gap="1">
        <Typography size="xs" color="gray">
          Added By {caseNote?.commentBy ?? caseNote?.addedBy}
        </Typography>
        <Typography size="xs" color="gray">
          |
        </Typography>
        <Typography size="xs" color="gray">
          {lastUpdatedAt ? format(lastUpdatedAt, 'dd MMM yy hh:mm aaa') : ''}
        </Typography>
      </Flex>
      <Divider />
    </Flex>
  );
};

export default NotesCard;
