import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { doPost } from '../../../../shared/service/NetworkClient';
import { ExistingEmailUser } from '../../../../shared/types/FetchByEmailUserData';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';

export const useGetUserFromEmail = ({
  email,
  organisation,
  onSuccess,
}: {
  email: CaseNoteFormTypes['email'];
  organisation: CaseNoteFormTypes['organisation'] | null;
  onSuccess?: (data: ExistingEmailUser) => void;
}) =>
  useQuery<ExistingEmailUser>(
    [QueryKeys.getUserFromEmail, email, organisation],
    () =>
      doPost(ApiConfig.api.clients.getDetailFromEmailV2, {
        email,
        organisation,
      }).then((res) => res.data.data),
    {
      select: (data) => ({
        ...data,
        ...('userId' in data && {
          organisation: {
            ...data.organisation,
            id: data.organisation.id,
          },
        }),
      }),
      enabled: false,
      onError: () => toast.error('No client with that email address exists.'),
      onSuccess,
    },
  );
