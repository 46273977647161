import { useQueryClient } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { SessionEvent } from '../../../shared/constants/events/SessionDetail';
import { QueryKeys } from '../../../shared/constants/QueryKeys';
import { useCreateComment } from '../../../utilities/hooks/queryHooks/session/UseCreateComment';
import { useGetSessionComments } from '../../../utilities/hooks/queryHooks/session/UseGetSessionComments';
import useAuth from '../../../utilities/hooks/UseAuth';

import useTracking from '../../../utilities/hooks/UseTracking';
import LoadingView from '../../components/loadingView/LoadingView';
import {
  Box,
  Button,
  Flex,
  TextArea,
  Typography,
} from '../../styledComponents';

const Comments = ({
  showCommentButton,
  handleSuccess,
}: {
  showCommentButton: boolean;
  handleSuccess: () => void;
}) => {
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>('');

  const { sessionId } = useParams();
  const { track } = useTracking<SessionEvent>();
  const { user } = useAuth();

  const {
    data: comments,
    isSuccess,
    isLoading,
    isRefetching,
  } = useGetSessionComments(sessionId);

  const queryClient = useQueryClient();

  const onCommentCreateSuccess = () => {
    track('save_comment', {
      eventAction: 'click',
      eventCategory: 'save_comment',
      eventLabel: 'save_comment',
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.getSessionComments, sessionId],
      exact: true,
    });
    setCommentText('');
    setShowCommentBox(false);
    handleSuccess();
  };

  const handleShowCommentBox = () => {
    track('add_comment', {
      eventAction: 'click',
      eventCategory: 'add_comment',
      eventLabel: 'add_comment',
    });
    setShowCommentBox(true);
  };

  const { isLoading: isCreateCommentLoading, mutate: mutateComment } =
    useCreateComment(onCommentCreateSuccess);

  return (
    <Box css={{ mt: '$6' }}>
      {isLoading || isRefetching ? (
        <LoadingView size="md" />
      ) : (
        isSuccess &&
        comments.map((comment) => {
          const commentTime = parseISO(comment.createdAt);
          return (
            <Box
              key={comment.id}
              css={{
                br: '$2',
                backgroundColor: '$gray50',
                p: '$5',
                border: '1px solid $gray200',
                my: '$3',
                fontSize: '$3',
                whiteSpace: 'pre-line',
              }}
            >
              {comment.note}

              <Flex justify="end" css={{ mt: '$2' }}>
                <Typography size="xs" color="gray500">
                  {format(commentTime, 'MMM dd, yyyy')} &#9679;{' '}
                  {format(commentTime, 'h:mm aaa')} &#9679;{' '}
                  {comment.commentBy ? comment.commentBy : 'provider'}
                </Typography>
              </Flex>
            </Box>
          );
        })
      )}

      {showCommentBox ? (
        <Box>
          <TextArea
            size="lg"
            css={{ mb: '$2', minHeight: '10rem' }}
            placeholder="Add your comment here..."
            onChange={(event) => setCommentText(event.target.value)}
            value={commentText}
            maxLength={5000}
          />
          <Flex justify="end" gap="3">
            <Button
              id="hide-comment-box"
              onClick={() => {
                setShowCommentBox(false);
                setCommentText('');
              }}
              size="lg"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              id="confirm-add-comment"
              onClick={() =>
                mutateComment({
                  sessionId: sessionId ?? '',
                  note: commentText.trim(),
                })
              }
              size="lg"
              isLoading={isCreateCommentLoading}
              disabled={!commentText.trim()}
              loadingText="Submitting..."
            >
              Save
            </Button>
          </Flex>
        </Box>
      ) : (
        <Flex justify="end" css={{ mt: '$2' }} gap="3">
          {user.addOns?.isCareNavigator ? null : (
            <Link
              to={`/${PathConfig.callLogs}/${sessionId}${PathConfig.editSession}`}
              onClick={() =>
                track('view_detail', {
                  eventAction: 'click',
                  eventCategory: 'edit_information',
                  eventLabel: 'edit_information',
                })
              }
            >
              <Button id="edit-session-button" size="lg" variant="outlined">
                Edit information
              </Button>
            </Link>
          )}

          {showCommentButton ? (
            <Button
              id="show-comment-box"
              onClick={handleShowCommentBox}
              size="lg"
              variant="outlined"
            >
              Comment
            </Button>
          ) : null}
        </Flex>
      )}
    </Box>
  );
};

export default Comments;
