import { styled } from '../../stitches.config';

const Divider = styled('div', {
  width: '100%',
  height: '1px',
  backgroundColor: '$gray200',
  margin: '$1 0',
});

export default Divider;
