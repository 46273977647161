import { EmailUserBaseMember } from '../../../../shared/types/FetchByEmailUserData';

export const getClientCreateWarning = ({ messages, memberships }: { messages?: any[], memberships?: EmailUserBaseMember[]; }) => {
  if (messages && messages.filter(mem => mem.status === 'DEPENDENT')?.length > 0) {
    return 'Email associated as dependent. ';
  }
  if (memberships?.length) {
    return `Account not found but Email exist in organisations: ${memberships?.map(m => `${m.name}(${m.id})`).join(',')}. `;
  }
  return 'Email not found in registered email list and dependents. ';
};
