import { FormProvider, useForm } from 'react-hook-form';
import Container from '../../../components/container/Container';
import { Heading } from '../../../styledComponents';
import FetchDetailsForm from './FetchDetailsForm';
import CareNavNote from './CareNavNoteForm';
import { CareNavForm } from '../../../../shared/types/CareNavForm';
import { UserDetail } from './UserDetail';

const CareNavUserDetail = () => {
  const methods = useForm<CareNavForm>({
    defaultValues: {
      email: '',
      reason: '',
      note: '',
      message: [],
      newUser: { name: '' },
    },
    mode: 'onTouched',
  });

  return (
    <FormProvider {...methods}>
      <Container>
        <div />
        <Heading size="lg" css={{ textAlign: 'center' }}>
          Add note
        </Heading>
        <FetchDetailsForm />
        <UserDetail />
        <CareNavNote />
      </Container>
    </FormProvider>
  );
};

export default CareNavUserDetail;
